<template>
  <v-container fluid>
    <v-tabs v-model="tabs">
      <v-tab>
        {{ $t('settings.lang_templates') }}
      </v-tab>
      <v-tab>
        {{ $t('settings.lang_settings') }}
      </v-tab>
    </v-tabs>
    <v-divider class="ma-0"/>
    <v-tabs-items v-model="tabs">
      <v-tab-item>
        <v-card>
          <v-card-title class="ma-0 pa-0">
            <v-btn style="margin: 20px" color="success" :disabled="loading" :loading="loading"
              @click="showCreateDialog = true">
              <v-icon>add</v-icon> {{ $t("erp.lang_newTemplate") }}
            </v-btn>
          </v-card-title>

          <v-card-text class="ma-0 pa-0">
            <Datatable ref="priceCheckTemplates" :api-endpoint="
            ENDPOINTS.SETTINGS.PRICECHECK_TERMINAL.TEMPLATES.DATATABLE
          " :datatable-headers="datatableHeaders" @editEntry="editEntry" @deleteEntry="deleteEntry"
              @deleteEntries="deleteEntries" show-delete-buttons show-edit-buttons />

            <v-dialog persistent max-width="400" v-model="showCreateDialog">
              <v-card>
                <v-card-title>
                  {{ $t("settings.lang_createTemplate") }}
                </v-card-title>

                <v-card-text class="text-center">
                  <v-text-field v-model="templateName" dense outlined :label="$t('erp.lang_TemplateName')" />
                </v-card-text>

                <v-card-actions>
                  <v-btn color="error" text :disabled="loading" @click="showCreateDialog = false">
                    {{ $t("generic.lang_cancel") }}</v-btn>
                  <v-spacer></v-spacer>
                  <v-btn color="success" :disabled="loading" :loading="loading" @click="createTemplate">
                    {{ $t("generic.lang_add") }}
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-card-text>
        </v-card>
      </v-tab-item>
      <v-tab-item>
        <v-card>
          <v-card-text>
            <v-form ref="form" v-model="valide" lazy-validation>
              <v-container>
                <v-row align="center">
                  <v-col cols="6">
                    <v-text-field :append-icon="visible ? 'mdi-eye-outline' : 'mdi-eye-off'"
                      :data-layout="KEYBOARD.KEYSETS.NORMAL" dense :label="$t('generic.lang_password')"
                      :type="visible ? 'text' : 'password'" @click:append="visible = !visible"
                      @focus="showTouchKeyboard" :rules="[(adminPw ? rules.minFour : true)]"
                      @keypress="acceptOnlyNumbers" autocomplete="off" aria-autocomplete="none"
                      :hint="$t('generic.lang_atLeast') + ' 4 ' + $t('generic.lang_numbers')" name="newPassword"
                      outlined v-model="adminPw" value="" />
                  </v-col>
                </v-row>
              </v-container>
            </v-form>
          </v-card-text>
          <v-divider class="ma-0"/>
          <v-card-actions class="border-top">
            <v-spacer />
            <v-btn :disabled="loading || !valide" @click="updateSettings()" color="success">
              {{ $t("generic.lang_apply") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-tab-item>
    </v-tabs-items>
  </v-container>
</template>

<script>
import validation from "@/mixins/validation/validation";
import sha512 from "js-sha512";
import { ENDPOINTS } from "@/config";
import mixin from "@/mixins/KeyboardMixIns";
import { Events } from "@/plugins/events";
import { mapState } from "vuex";
import Datatable from "@/components/datatable/Datatable";

export default {
  name: "Templates",
  components: {
    Datatable,
  },
  mixins: [mixin, validation],

  data() {
    return {
      ENDPOINTS,
      loading: false,
      tabs:0,
      showCreateDialog: null,
      templateName: "",
      valide: false,
      visible: false,
      adminPw: null,
    };
  },

  computed: {
    ...mapState(["api"]),
    datatableHeaders() {
      return [
        {
          text: "ID",
          align: "left",
          value: "id",
          width: 80,
          hide: true,
        },
        { text: this.$t("generic.lang_name"), value: "name" },
      ];
    },
  },
  methods: {
    updateSettings() {
      if (!this.$refs.form.validate()) {
        return;
      }
      this.loading = true;

      this.axios
        .post(
          ENDPOINTS.SETTINGS.PRICECHECK_TERMINAL.SETTINGS.ADMINPW,
          {
            priceCheckerAdminPw: this.adminPw?sha512(this.adminPw):'',
          }
        )
        .then((res) => {
          Events.$emit("showSnackbar", {
            message: this.$t("generic.lang_updatedSuccessfully"),
            color: "success",
          });
        })
        .catch((err) => {
          Events.$emit("showSnackbar", {
            message: err.message,
            color: "error",
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    createTemplate() {
      this.loading = true;

      this.axios
        .post(ENDPOINTS.SETTINGS.PRICECHECK_TERMINAL.TEMPLATES.CREATE, {
          templateName: this.templateName,
        })
        .then((res) => {
          if (res.data.success) {
            this.$router.push({
              name: "settings.cashierSettings.priceCheckTerminalTemplate",
              params: {
                id: res.data.templateID,
              },
            });
          }
        })
        .catch(() => {})
        .finally(() => {
          this.loading = false;
        });
    },
    editEntry(entry) {
      this.$router.push({
        name: "settings.cashierSettings.priceCheckTerminalTemplate",
        params: { id: entry.id },
      });
    },
    deleteEntry(entry) {
      let id = [];
      id.push(entry.id);
      this.deleteData(id);
    },
    deleteEntries(entries) {
      this.deleteData(entries);
    },
    deleteData(ids) {
      let self = this;
      this.$swal({
        title: this.$t("settings.lang_deletePriceCheckerTemplateHead"),
        text: this.$t("settings.lang_deletePriceCheckerTemplateBody"),
        icon: "warning",
        showCancelButton: true,
        showLoaderOnConfirm: true,
        cancelButtonText: this.$t("generic.lang_cancel"),
        confirmButtonText: this.$t("generic.lang_delete"),
        preConfirm: () => {
          this.axios
            .post(ENDPOINTS.SETTINGS.PRICECHECK_TERMINAL.TEMPLATES.DELETE, {
              id: ids,
            })
            .then((res) => {
              if (res.data.status == "SUCCESS") {
                Events.$emit("showSnackbar", {
                  message: this.$t("generic.lang_ActionSuccessful"),
                  color: "success",
                });
                self.$refs.priceCheckTemplates.getDataFromApi();
                self.$refs.priceCheckTemplates.resetSelectedRows();
              } else {
                Events.$emit("showSnackbar", {
                  message: this.$t("generic.lang_errorOccurred"),
                  color: "error",
                });
              }
            });
        },
        allowOutsideClick: () => !this.$swal.isLoading,
      });
    },
  },
};
</script>
